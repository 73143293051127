/* eslint-disable no-console */
/* eslint-disable max-len */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import i18next from 'i18next'
import currencies from '../data/currencies'

const CurrencyContext = createContext()

export function useCurrency() {
  return useContext(CurrencyContext)
}

export function CurrencyProvider({ children }) {
  const [currency, setCurrency] = useState(JSON.parse(localStorage.getItem('selected_currency')) || currencies[0])

  const changeCurrency = async (ticker = 'USD') => {
    const foundCurrency = currencies.find((item) => item.ticker.toUpperCase() === ticker.toUpperCase())

    if (!foundCurrency) {
      return console.warn(`No currency found with ticker ${ticker}, please use a valid symbol or add this one to /src/data/currencies.js`)
    }

    localStorage.setItem('selected_currency', JSON.stringify(foundCurrency))
    await i18next.changeLanguage(foundCurrency.language.value)
    setCurrency(foundCurrency)
  }

  useEffect(() => {
    i18next.changeLanguage(currency.language.value)
  }, [])

  return (
    <CurrencyContext.Provider value={{ currencies, currency, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  )
}
