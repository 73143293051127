import Immutable from 'seamless-immutable'
import { v4 } from 'uuid'
import { handleActions } from 'redux-actions'
import { appTypes } from '../actions/types'

const initialState = Immutable({
  2: {
    status: 'Approved',
    amount: '10,000',
    plan: 'Car',
    dueDate: '22.08.2022',
    rate: 3,
    description: 'For Dafna university',
    returned: '0',
    remaining: '10,300',
  },
  3: {
    status: 'Pending',
    amount: '100,000',
    plan: 'Mortgage',
    dueDate: '10.04.2022',
    rate: 6,
    description: 'New car',
    returned: '42,000',
    remaining: '64,000',
  },
  4: {
    status: 'Canceled',
    amount: '15,000',
    plan: 'Students',
    dueDate: '7 Days',
    rate: 3,
    description: 'PS5',
    returned: '13,000',
    remaining: '2,450',
  },
})

export default handleActions({
  [appTypes.addLoan]: (state, { value }) => state.set(v4(), value),
}, initialState)
