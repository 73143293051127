/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react'
import { Avatar } from '@material-ui/core'
import IOSSwitch from './IOSSwitch'

export default [
  {
    title: (
      <>
        <div>High end 15-16</div>
        <div style={{ fontSize: 12, marginTop: 3 }}>Last edited 2021/02/23</div>
      </>
    ),
    amount: '₪ 9,100',
    invested: '₪ 9,000',
    reinvested: '₪ 100',
    interest: '13.0% - 20.2%',
    term: '1m. - 12m.',
    singleLoan: (
      <>
        <div>Min: 100 NIS</div>
        <div>Max: 1000 NIS</div>
      </>
    ),
    reinvest: <Avatar style={{ backgroundColor: '#75c9c9', fontWeight: 'bold' }}>R</Avatar>,
    status: <IOSSwitch checked />,
  },
  {
    title: (
      <>
        <div>High end 15-16</div>
        <div style={{ fontSize: 12, marginTop: 3 }}>Last edited 2021/02/23</div>
      </>
    ),
    amount: '₪ 9,100',
    invested: '₪ 9,000',
    reinvested: '₪ 100',
    interest: '13.0% - 20.2%',
    term: '1m. - 12m.',
    singleLoan: (
      <>
        <div>Min: 100 NIS</div>
        <div>Max: 1000 NIS</div>
      </>
    ),
    reinvest: <Avatar style={{ backgroundColor: '#75c9c9', fontWeight: 'bold' }}>R</Avatar>,
    status: <IOSSwitch />,
  },
  {
    title: (
      <>
        <div>High end 15-16</div>
        <div style={{ fontSize: 12, marginTop: 3 }}>Last edited 2021/02/23</div>
      </>
    ),
    amount: '₪ 9,100',
    invested: '₪ 9,000',
    reinvested: '₪ 100',
    interest: '13.0% - 20.2%',
    term: '1m. - 12m.',
    singleLoan: (
      <>
        <div>Min: 100 NIS</div>
        <div>Max: 1000 NIS</div>
      </>
    ),
    reinvest: <Avatar style={{ backgroundColor: '#75c9c9', fontWeight: 'bold' }}>R</Avatar>,
    status: <IOSSwitch checked />,
  },
  {
    title: (
      <>
        <div>High end 15-16</div>
        <div style={{ fontSize: 12, marginTop: 3 }}>Last edited 2021/02/23</div>
      </>
    ),
    amount: '₪ 9,100',
    invested: '₪ 9,000',
    reinvested: '₪ 100',
    interest: '13.0% - 20.2%',
    term: '1m. - 12m.',
    singleLoan: (
      <>
        <div>Min: 100 NIS</div>
        <div>Max: 1000 NIS</div>
      </>
    ),
    reinvest: <Avatar style={{ backgroundColor: 'rgb(232, 145, 143)', fontWeight: 'bold' }}>R</Avatar>,
    status: <IOSSwitch checked />,
  },
  {
    title: (
      <>
        <div>High end 15-16</div>
        <div style={{ fontSize: 12, marginTop: 3 }}>Last edited 2021/02/23</div>
      </>
    ),
    amount: '₪ 9,100',
    invested: '₪ 9,000',
    reinvested: '₪ 100',
    interest: '13.0% - 20.2%',
    term: '1m. - 12m.',
    singleLoan: (
      <>
        <div>Min: 100 NIS</div>
        <div>Max: 1000 NIS</div>
      </>
    ),
    reinvest: <Avatar style={{ backgroundColor: '#75c9c9', fontWeight: 'bold' }}>R</Avatar>,
    status: <IOSSwitch />,
  },
  {
    title: (
      <>
        <div>High end 15-16</div>
        <div style={{ fontSize: 12, marginTop: 3 }}>Last edited 2021/02/23</div>
      </>
    ),
    amount: '₪ 9,100',
    invested: '₪ 9,000',
    reinvested: '₪ 100',
    interest: '13.0% - 20.2%',
    term: '1m. - 12m.',
    singleLoan: (
      <>
        <div>Min: 100 NIS</div>
        <div>Max: 1000 NIS</div>
      </>
    ),
    reinvest: <Avatar style={{ backgroundColor: '#75c9c9', fontWeight: 'bold' }}>R</Avatar>,
    status: <IOSSwitch checked />,
  },
]
