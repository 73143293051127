/* eslint-disable import/prefer-default-export */
const appTypes = {
  backToState: 'BACK_TO_STATE',
  helloRedux: 'HELLO_REDUX',
  addLoan: 'addLoan',
}

export {
  appTypes,
}
