/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg viewBox="0 0 48 48"><path d="m23.77 20.909c3.0125 0 5.4546-2.4421 5.4546-5.4546s-2.4421-5.4546-5.4546-5.4546-5.4546 2.4421-5.4546 5.4546 2.4421 5.4546 5.4546 5.4546z" fill="#fff" stroke="#009B9A" stroke-linecap="round" stroke-linejoin="round"></path><path d="m35.82 36.205c0.0021 0.8317-0.0845 1.6612-0.2582 2.4745h-23.303c-0.1737-0.8133-0.2603-1.6428-0.2582-2.4745-0.0156-1.5785 0.2802-3.1446 0.8706-4.6087 0.5903-1.4641 1.4636-2.7973 2.5698-3.9235s2.4236-2.0232 3.8769-2.6397 3.0138-0.9403 4.5924-0.9529c1.5786 0.0126 3.1391 0.3364 4.5924 0.9529s2.7707 1.5135 3.8769 2.6397 1.9795 2.4594 2.5698 3.9235c0.5904 1.4641 0.8862 3.0302 0.8706 4.6087z" fill="#fff" stroke="#009B9A" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
