import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import { CONSENT_COLORS } from '../../../consts'

const useStyles = makeStyles(({ spacing, palette }) => ({
  namesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  listIndex: {
    marginRight: ({ isRtl }) => (isRtl ? 0 : spacing(2)),
    marginLeft: ({ isRtl }) => (isRtl ? spacing(2) : 0),
    display: 'inline-grid',
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyItems: 'center',
    background: palette.grey[400],
    borderRadius: '50%',
    fontSize: 14,
  },
  consentText: {
    fontWeight: 'bold',
    fontSize: 17,
    display: 'inline-flex',
    margin: spacing(),
    marginLeft: 0,
    marginRight: 0,
  },
  status: {
    marginRight: ({ isRtl }) => (!isRtl ? 0 : spacing(10)),
    marginLeft: ({ isRtl }) => (!isRtl ? spacing(10) : 0),
  },
  line: {
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  validUntil: {
    marginBottom: spacing(),
    display: 'inline-flex',
  },
  moreButton: {
    marginRight: ({ isRtl }) => (!isRtl ? 0 : 'auto'),
    marginLeft: ({ isRtl }) => (!isRtl ? 'auto' : 0),
    color: ({ consent: { status } }) => CONSENT_COLORS[status],
    transition: '0.4s',
  },
  plusIcon: {
    animation: '$rotate 200ms linear',
    animationDirection: 'reverse',
  },
  minusIcon: {
    animation: '$rotateBack 200ms linear',
    animationDirection: 'reverse',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotateZ(0)',
    },
    '25%': {
      transform: 'rotateZ(45deg)',
    },
    '50%': {
      transform: 'rotateZ(90deg)',
    },
    '100%': {
      transform: 'rotateZ(180deg)',
    },
  },
  '@keyframes rotateBack': {
    '0%': {
      transform: 'rotateZ(0)',
    },
    '25%': {
      transform: 'rotateZ(-45deg)',
    },
    '50%': {
      transform: 'rotateZ(-90deg)',
    },
    '100%': {
      transform: 'rotateZ(-180deg)',
    },
  },
}), { name: 'AccordionHeader' })

function AccordionHeader(props) {
  const classes = useStyles(props)
  const {
    open,
    setOpen,
    consent,
  } = props

  const {
    amount,
    dueDate,
    description = '',
  } = consent

  return (
    <div className={classes.container}>
      <div
        className={classes.listIndex}
      >
        <div
          className={classes.line}
          role="button"
          tabIndex={0}
          style={{
            backgroundImage: 'url(http://cdn.onlinewebfonts.com/svg/img_187814.png)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          alt="grid-button"
        />
      </div>
      <div className={classes.namesContainer}>
        <div
          className={classes.consentText}
        >
          {`${amount} $`}
        </div>
        <div className={classes.validUntil}>
          {dueDate}
        </div>
      </div>
      <div className={classes.status}>
        <Typography>
          {description}
        </Typography>
      </div>
      <div className={classes.moreButton}>
        <IconButton className={classes.moreButton} onClick={() => setOpen(!open)}>
          {open && (
            <ArrowUpwardIcon
              className={classes.minusIcon}
              fontSize="large"
            />
          )}
          {!open && (
            <ArrowDownwardIcon
              className={classes.plusIcon}
              fontSize="large"
            />
          )}
        </IconButton>
      </div>
    </div>
  )
}

export default AccordionHeader
