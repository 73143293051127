/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg class="MuiSvgIcon-root jss3518" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
  )
}

export default HouseIcon
