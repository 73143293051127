import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchBar from '../Invest/SearchBar'
import TableSecond from './TableSecond'

const investments = [
  {
    id: '184192',
    type: 'Car Loan',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
  {
    id: '184192',
    type: 'Mortgage',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
  {
    id: '184192',
    type: 'Car Loan',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
  {
    id: '184192',
    type: 'Mortgage',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
  {
    id: '184192',
    type: 'Car Loan',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
  {
    id: '184192',
    type: 'Car Loan',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
  {
    id: '184192',
    type: 'Taxes',
    apr: '24.3%',
    intrest: '8.5%',
    term: '22m. 9 d.',
    ytm: '20.2%',
    available: '₪ 2,472.52',
  },
]

const headers = [
  { header: '', minWidth: 50 },
  { header: 'ID', minWidth: 70 },
  { header: 'Loan Type', minWidth: 100 },
  { header: 'Borrower APR', minWidth: 70 },
  { header: 'Intrest Rate', minWidth: 70 },
  { header: 'Term' },
  { header: 'YTM', minWidth: 70 },
  { header: 'Available for Investment' },
]

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 256px)',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
  },
}), { name: 'Content' })

export default function Content(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <SearchBar isPaper />
      <TableSecond headers={headers} items={investments} />
    </div>
  )
}
