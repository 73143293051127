import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Card from './Card'
import CardMain from './CardMain'
import CardProtfolio from './CardProtfolio'

function createData(time, amount) {
  return { time, amount }
}

const data1 = [
  createData('Jan', 167),
  createData('Feb', 187),
  createData('Mar', 171),
  createData('Apr', 192),
  createData('May', 172),
  createData('Jun', 199),
  createData('Jul', 204),
  createData('Aug', 193),
  createData('Sep', 206),
]

const data2 = [
  createData('Jan', 260),
  createData('Feb', 150),
  createData('Mar', 200),
  createData('Apr', 180),
  createData('May', 270),
  createData('Jun', 200),
  createData('Jul', 320),
  createData('Aug', 240),
  createData('Sep', 280),
]

const data3 = [
  createData('Jan', 260),
  createData('Feb', 240),
  createData('Mar', 240),
  createData('Apr', 200),
  createData('May', 150),
  createData('Jun', 110),
  createData('Jul', 90),
  createData('Aug', 70),
  createData('Sep', 80),
]

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}), { name: 'Overview' })

export default function Overview() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {/* cards */}
      <Card
        header="Account Value"
        subHeader="₪ 20,630"
        precent="23%"
        increase
        buttonText="Deposit"
        data={data1}
        id="color1"
      />
      <Card
        header="Available Balance"
        subHeader="₪ 2,830"
        precent="8%"
        increase
        buttonText="Invest"
        data={data2}
        id="color2"
      />
      <Card
        header="Loans"
        subHeader="₪ 8,630"
        precent="70%"
        buttonText="Borrow"
        data={data3}
        id="color3"
      />
      <CardProtfolio />
      <CardMain />
    </div>
  )
}
