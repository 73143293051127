import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ConsentList from './ConsentList'

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    maxWidth: '100%',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
  },
  buttonContainer: {
    marginTop: spacing(3),
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
}), { name: 'DashBoard' })

function DashBoard(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <ConsentList />
    </div>
  )
}

export default DashBoard
