import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container, Grid, Slider, withStyles,
} from '@material-ui/core'

const ytmConverter = (ytm) => {
  const v = `${Math.floor(ytm / 4) + 5}%`
  if (ytm === 100) return `${v}+`
  return v
}

const termConverter = (term) => {
  const v = `${Math.floor(term / 2)}m.`
  if (term === 100) return `${v}+`
  return v
}

const useStyles = makeStyles(() => ({
  header: {
    marginTop: 25,
    marginBottom: 5,
  },
}), { name: 'Sliders' })

const AirbnbSlider = withStyles({
  root: {
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider)

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  )
}

export default function Sliders(props) {
  const [ytm, setYtm] = useState([50, 100])
  const [term, setTerm] = useState([0, 50])
  const classes = useStyles(props)

  const handleChangeYtm = (event, newValue) => {
    setYtm(newValue)
  }

  const handleChangeTerm = (event, newValue) => {
    setTerm(newValue)
  }

  const termMarks = [
    {
      value: 0,
      label: termConverter(term[0]),
    },
    {
      value: 100,
      label: termConverter(term[1]),
    },
  ]

  const ytmMarks = [
    {
      value: 0,
      label: ytmConverter(ytm[0]),
    },
    {
      value: 100,
      label: ytmConverter(ytm[1]),
    },
  ]

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div style={{ paddingRight: 7 }}>
            <div className={classes.header}>Interest</div>
            <AirbnbSlider
              ThumbComponent={AirbnbThumbComponent}
              value={ytm}
              onChange={handleChangeYtm}
              valueLabelDisplay="on"
              aria-labelledby="range-slider"
              marks={ytmMarks}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ paddingLeft: 7 }}>
            <div className={classes.header}>Term</div>
            <AirbnbSlider
              ThumbComponent={AirbnbThumbComponent}
              value={term}
              onChange={handleChangeTerm}
              valueLabelDisplay="on"
              aria-labelledby="range-slider"
              marks={termMarks}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
