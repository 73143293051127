/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BigButton from '../../Borrow/DialogItems/BigButton'
import W2 from '../../../../assets/icons/W2'
import Misc from '../../../../assets/icons/Misc'
import SelfIncome from '../../../../assets/icons/SelfIncome'

const options = [
  {
    name: 'BEGINNER',
    sub: '8.0% - 12.0% Return, 1m. - 6m., Investing per single loan 10 - 100',
    icon: () => <W2 />,
  },
  {
    name: 'PROFESSIONAL',
    sub: '8.0% - 12.0% Return, 6m. - 12m., Investing per single loan 100 - 1,000',
    icon: () => <SelfIncome />,
  },
  {
    name: 'EXPERT',
    sub: '10.0% - 13.0% Return, 7m. - 12m., Investing per single loan 1,000 - 10,000',
    icon: () => <Misc />,
  },
  {
    name: 'CUSTOM',
    sub: 'make your own choices',
    icon: () => <Misc />,
  },
]

const useStyles = makeStyles(() => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    width: '25.5rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '6rem',
    margin: '0.25rem',
    borderRadius: 8,
    transition: 'all 0.175s ease 0s',
    border: '0.0625rem solid rgb(230, 230, 230)',
    cursor: 'pointer',
    '&[aria-selected="true"]': {
      border: '0.0625rem solid rgb(0, 176, 175)',
      boxShadow: 'rgb(0 176 175 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 251, 251)',
    },
    '&:hover': {
      border: '0.0625rem solid rgb(0, 176, 175)',
      boxShadow: 'rgb(0 176 175 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 251, 251)',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '0.715rem',
    letterSpacing: '0.0625rem',
    transition: 'all 0.175s ease 0s',
    lineHeight: '0.8125rem',
    marginTop: '0.625rem',
    color: 'rgb(97, 97, 97)',
    '&[aria-selected="true"]': {
      color: 'rgb(0, 176, 175)',
    },
    '&:hover': {
      color: 'rgb(0, 176, 175)',
    },
  },
  subText: {
    fontWeight: 400,
    fontSize: '0.515rem',
    letterSpacing: '0.0625rem',
    transition: 'all 0.175s ease 0s',
    lineHeight: '0.8125rem',
    marginBottom: '0.625rem',
    color: 'rgb(97, 97, 97)',
    '&[aria-selected="true"]': {
      color: 'rgb(0, 176, 175)',
    },
    '&:hover': {
      color: 'rgb(0, 176, 175)',
    },
  },
}), { name: 'WhatPolicy' })

export default function WhatPolicy(props) {
  const [selected, setSelected] = useState('HOME IMPROVMENT')
  const classes = useStyles(props)

  const { setStep } = props

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        Create New Auto Invest Policy!
      </div>
      <div className={classes.subHeader}>
        Choose your path
      </div>
      <div className={classes.optionsContainer}>
        {options.map(({ name, sub, icon }) => (
          <div
            onClick={() => setSelected(name)}
            aria-selected={selected === name}
            key={name}
            className={classes.card}
          >
            <div style={{ width: 48, height: 48 }}>
              {icon()}
            </div>
            <div aria-selected={selected === name} className={classes.text}>
              {name}
            </div>
            <div aria-selected={selected === name} className={classes.subText}>
              {sub}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.buttonContainer}>
        <BigButton onClick={() => setStep(1)} label="Continue" />
      </div>
    </div>
  )
}
