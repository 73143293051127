/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="33" height="23" viewBox="0 0 33 23" fill="none"><rect x="0.500244" y="0.580566" width="31.9302" height="21.8387" rx="2.63953" fill="white" stroke="#00B0AF"></rect><rect x="0.500244" y="6.1499" width="31.9273" height="4.23694" stroke="#00B0AF"></rect></svg>
  )
}

export default HouseIcon
