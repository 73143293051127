/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none"><rect x="-0.707107" y="5.96046e-08" width="15.9814" height="30.6471" rx="7.99069" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 33.9858 11.8164)" fill="white" stroke="#00B0AF"></rect><path d="M27.0061 8.49512V8.49512C24.6307 6.11971 20.7506 6.21666 18.4968 8.70772L17.4546 9.85962" stroke="#00B0AF"></path><line y1="-0.5" x2="15.4376" y2="-0.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 23.1853 23.2319)" stroke="#00B0AF"></line></svg>
  )
}

export default HouseIcon
