/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg style={{transform: 'scale(1,1) scale(1,-1)'}} class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z"></path></svg>
  )
}

export default HouseIcon
