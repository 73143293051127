/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BigButton from './BigButton'
import BlurIconTextField from './BlurIconTextField'
import Formatter from '../../../InputFormatters/Formatter'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import Errors from '../../../Errors'

const useStyles = makeStyles(({ palette }) => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '25.5rem',
  },
  names: {
    width: '50%',
    padding: 5,
  },
  rest: {
    width: '100%',
    margin: 5,
  },
  icon: {
    color: palette.primary.main,
  },
}), { name: 'Financial' })

export default function Financial(props) {
  const { t } = useTranslation()
  const { currency } = useCurrency()
  const classes = useStyles(props)

  const { setStep, data } = props
  const {
    annual_income, set_annual_income,
    additional_income, set_additional_income,
    monthly_rent_or_mortgage, set_monthly_rent_or_mortgage,
    errors, setErrors,
  } = data

  const clickContinue = () => {
    const errorsToSet = []

    if (!annual_income) errorsToSet.push('Annual income is required')
    if (!monthly_rent_or_mortgage) errorsToSet.push('Monthly expenses is required')

    setErrors(errorsToSet)

    if (!errorsToSet.length) {
      setStep(7)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {t('Tell us about your financial picture')}
      </div>
      <div className={classes.subHeader}>
        {t('We may need to confirm this info later so try to be as exact as possible')}
      </div>
      <div className={classes.optionsContainer}>
        <BlurIconTextField
          value={annual_income}
          onChange={(value) => set_annual_income(value)}
          inputComp={Formatter}
          prefix={currency.symbol}
          label={t('Your Annual Income')}
          className={classes.rest}
          required
        />
        <BlurIconTextField
          value={additional_income}
          onChange={(value) => set_additional_income(value)}
          inputComp={Formatter}
          prefix={currency.symbol}
          label={t('Additional Income')}
          className={classes.rest}
        />
        <BlurIconTextField
          value={monthly_rent_or_mortgage}
          onChange={(value) => set_monthly_rent_or_mortgage(value)}
          inputComp={Formatter}
          prefix={currency.symbol}
          label={t('Monthly Rent or Mortgage Payment')}
          className={classes.rest}
          required
        />
      </div>

      <Errors errors={errors} />

      <div className={classes.buttonContainer}>
        <BigButton onClick={clickContinue} label={t('Continue')} />
      </div>
    </div>
  )
}
