/* eslint-disable import/prefer-default-export */
import { appTypes } from './types'

const addLoan = (value) => ({
  type: appTypes.addLoan,
  value,
})

export {
  addLoan,
}
