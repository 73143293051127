/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg fill="none" viewBox="0 0 48 48"><path d="m43.312 37.522h-38.213c-0.06703-0.5628-0.10034-1.1292-0.09977-1.6961 0-7.4053 5.432-13.414 12.128-13.414 2.1243 0.0381 4.1995 0.6456 6.009 1.759s3.2871 2.6919 4.2786 4.571c1.5718-0.9741 3.3834-1.4922 5.2325-1.4966 5.4431 0 9.944 4.4787 10.665 10.276z" fill="#fff" stroke="#009B9A" stroke-linecap="round" stroke-linejoin="round"></path><path d="m17.128 19.241c3.1041 0 5.6205-2.5164 5.6205-5.6205s-2.5164-5.6205-5.6205-5.6205-5.6205 2.5164-5.6205 5.6205 2.5164 5.6205 5.6205 5.6205z" fill="#fff" stroke="#009B9A" stroke-linecap="round" stroke-linejoin="round"></path><path d="m32.648 23.753c2.5347 0 4.5895-2.0548 4.5895-4.5896 0-2.5347-2.0548-4.5895-4.5895-4.5895s-4.5895 2.0548-4.5895 4.5895c0 2.5348 2.0548 4.5896 4.5895 4.5896z" fill="#fff" stroke="#009B9A" stroke-linecap="round" stroke-linejoin="round"></path><path d="m37.858 28.742c-1.5717-0.9741-3.3833-1.4923-5.2324-1.4966-1.3725 0.0112-2.7287 0.2987-3.9876 0.8453-1.259 0.5466-2.3949 1.3412-3.3401 2.3363" fill="#fff"></path><path d="m37.858 28.742c-1.5717-0.9741-3.3833-1.4923-5.2324-1.4966-1.3725 0.0112-2.7287 0.2987-3.9876 0.8453-1.259 0.5466-2.3949 1.3412-3.3401 2.3363" stroke="#009B9A" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
