/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {
  IconButton, Slide, Toolbar,
} from '@material-ui/core'

import WhatPolicy from './DialogItems/WhatPolicy'
import CustomPolicy from './DialogItems/CustomPolicy'
import Loading from './DialogItems/Loading'

const useStyles = makeStyles(({ spacing }) => ({
  dialog: {
    width: 700,
    height: 662,
  },
  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    zIndex: 3,
    border: 0,
  },
  logoContainer: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 20,
    },
  },
}), { name: 'AutoInvestDialog' })

const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />))

export default function AutoInvestDialog(props) {
  const classes = useStyles()
  const {
    handleClose,
    open,
    setStep,
    step,
  } = props

  const backClicked = () => {
    if (step === 0) return handleClose()
    setStep(step - 1)
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className={classes.dialog}>
        <div>
          <Toolbar className={classes.appBar}>
            <IconButton edge="start" color="inherit" onClick={backClicked} aria-label="close">
              <ArrowBack />
            </IconButton>
            <div className={classes.logoContainer}>
              <div>Name</div>
            </div>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </div>
        <div>
          {(step === 0) && (<WhatPolicy setStep={setStep} />)}
          {(step === 1) && (<CustomPolicy setStep={setStep} />)}
          {(step === 2) && (<Loading handleClose={handleClose} />)}
        </div>
      </div>
    </Dialog>
  )
}
