import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ClassIcon from '@material-ui/icons/Class'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import BallotIcon from '@material-ui/icons/Ballot'
import { DRAWER_WIDTH } from './consts'

const navItems = [
  {
    text: 'Overview',
    icon: () => (<ClassIcon />),
    path: 'main-screen',
  },
  {
    text: 'Invest',
    icon: () => (<LocalAtmIcon />),
    path: 'invest',

  },
  {
    text: 'Auto Invest',
    icon: () => (<BallotIcon />),
    path: 'autoinvest',

  },
  {
    text: 'Borrow',
    icon: () => (<InboxIcon />),
    path: 'borrow',

  },
]

const useStyles = makeStyles(({ palette }) => ({
  listItem: {
    width: DRAWER_WIDTH - 20,
    paddingTop: 13,
    color: 'rgb(183,180,200)',
    paddingBottom: 13,
    marginTop: 20,
    letterSpacing: 1.5,
    marginLeft: 10,
    cursor: 'pointer',
    '&[aria-selected="true"]': {
      color: palette.primary.main,
      backgroundColor: 'white',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    },
  },
  icon: {
    color: 'rgb(183,180,200)',
    '&[aria-selected="true"]': {
      color: palette.primary.main,
    },
  },
}), { name: 'NavBar' })

export default function NavBar(props) {
  const classes = useStyles(props)
  const history = useHistory()
  const location = useLocation()

  const checkPath = (path) => location.pathname === `/${path}`
  const changePath = (path) => history.push(path)

  return (
    <List>
      {navItems.map(({ text, path, icon }) => (
        <ListItem
          aria-selected={checkPath(path)}
          onClick={() => changePath(path)}
          className={classes.listItem}
          key={text}
        >
          <ListItemIcon aria-selected={checkPath(path)} className={classes.icon}>{icon()}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  )
}
