/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BigButton from './BigButton'
import BlurIconTextField from './BlurIconTextField'
import Errors from '../../../Errors'

const useStyles = makeStyles(({ palette }) => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '25.5rem',
  },
  names: {
    width: '50%',
    padding: 5,
  },
  rest: {
    width: '100%',
    margin: 5,
  },
  icon: {
    color: palette.primary.main,
  },
}), { name: 'Address' })

export default function Address(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const { setStep, data } = props
  const {
    street, set_street,
    city, set_city,
    state, set_state,
    zip_code, set_zip_code,
    errors, setErrors,
  } = data

  const clickContinue = () => {
    const errorsToSet = []

    if (!street) errorsToSet.push('Address is required')
    if (!city) errorsToSet.push('City is required')
    if (!state) errorsToSet.push('State is required')
    if (!zip_code) errorsToSet.push('Zip code is required')
    setErrors(errorsToSet)

    if (!errorsToSet.length) {
      setStep(5)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {t('Great! Just a little more info')}
      </div>
      <div className={classes.optionsContainer}>
        <BlurIconTextField
          value={street}
          onChange={(value) => set_street(value)}
          label={t('Home Address')}
          className={classes.rest}
          required
        />
        <BlurIconTextField
          value={city}
          onChange={(value) => set_city(value)}
          label={t('City')}
          className={classes.rest}
          required
        />
        <div className={classes.names}>
          <BlurIconTextField
            value={state}
            onChange={(value) => set_state(value)}
            label={t('State')}
            className=""
            required
          />
        </div>
        <div className={classes.names}>
          <BlurIconTextField
            value={zip_code}
            onChange={(value) => set_zip_code(value)}
            label={t('Zip Code')}
            className=""
            required
          />
        </div>
      </div>

      <Errors errors={errors} />

      <div className={classes.buttonContainer}>
        <BigButton onClick={clickContinue} label={t('Continue')} />
      </div>
    </div>
  )
}
