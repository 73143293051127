import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiTableCell from '@material-ui/core/TableCell'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import {
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  withStyles,
  Avatar,
} from '@material-ui/core'
import TagifyTag from './TagifyTag'

const randIcon = [
  'https://mivtland.menoramivt.co.il/wp-content/uploads/2017/08/menora-logo-notext.png',
  'https://yt3.ggpht.com/ytc/AAUvwngxw-k5cAA6_r_i6Dns2YANxfaHjy8oXj6cU0Wm=s900-c-k-c0x00ffffff-no-rj',
  // eslint-disable-next-line max-len
  'https://lh3.googleusercontent.com/proxy/1p7ifwwmyLOozVnwUvrhVWMla-Y8sKUX3LL7VG7t-tPQFpFCdr4boYdldXBH2PFS9H5G8kC6BjMok6vr_ctHy21mUkEqFnhFODNIOQ',
]

const TableCell = withStyles(() => ({
  head: {
    fontWeight: 'bold',
  },
}))(MuiTableCell)

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    background: palette.background.default, // here
    display: 'flex',
    flexDirection: 'column',
    border: '0px solid #ccc',
    borderRadius: 6,
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.1)',
  },
  cardHeader: {
    fontSize: 17,
    margin: 8,
  },
  flexContainer: {
    display: 'flex',
  },
  header: {
    padding: 10,
    width: 105,
    alignSelf: 'center',
  },
  endButton: {
    padding: 10,
    width: 155,
    alignSelf: 'center',
  },
  investButton: {
    width: '100%',
  },
  investHeader: {
    color: 'white',
    borderColor: 'rgba(255, 255, 255, 0.7)',
  },
}), { name: 'TableSecond' })

export default function TableSecond(props) {
  const classes = useStyles()

  const {
    headers,
    items,
  } = props

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map(({ header, minWidth }) => (
              <TableCell style={{ minWidth: minWidth || 100 }} align="center" key={header}>{header}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, i) => (
            <TableRow key={item.id}>
              <TableCell align="center">
                <Avatar src={randIcon[i % (randIcon.length)]} />
              </TableCell>
              {Object.values(item).map((row, j) => (
                <TableCell align="center" key={row}>
                  <TagifyTag row={row} putClass={j === 1} />
                </TableCell>
              ))}
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.investButton}
                  startIcon={<AddShoppingCartIcon />}
                >
                  Invest
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
