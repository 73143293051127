import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Popover,
  List,
  ListSubheader,
  ListItemText,
} from '@mui/material'
import {
  ArrowDropDownRounded,
} from '@material-ui/icons'
import { useCurrency } from '../contexts/CurrencyContext'

function SelectLanguageAndCurrency() {
  const { currencies, currency, changeCurrency } = useCurrency()
  const { t } = useTranslation()
  const [menuTarget, setMenuTarget] = useState(null)

  const changeItem = (item) => {
    changeCurrency(item.ticker)
    setMenuTarget(null)
  }

  return (
    <div>
      <Button onClick={({ currentTarget }) => setMenuTarget(currentTarget)} style={{ color: 'white' }}>
        {currency.language.label}
        <ArrowDropDownRounded fontSize="medium" />
      </Button>

      <Popover
        open={!!menuTarget}
        anchorEl={menuTarget}
        onClose={() => setMenuTarget(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List style={{ minWidth: '100px' }}>
          <ListSubheader style={{ textAlign: 'center' }}>{t('select_language')}</ListSubheader>
          {currencies.map((item) => (
            <ListItemText
              key={item.ticker}
              onClick={() => changeItem(item)}
              style={{ textAlign: 'center', cursor: 'pointer' }}
            >
              {item.language.label}
            </ListItemText>
          ))}
        </List>
      </Popover>
    </div>
  )
}

export default SelectLanguageAndCurrency
