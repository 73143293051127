/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputAdornment, TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.primary.main,
  },
}), { name: 'BlurIconTextField' })

export default function BlurIconTextField(props) {
  const [icons, setIcons] = useState(false)
  const classes = useStyles(props)

  const {
    label,
    className,
    inputComp,
    variant = 'outlined',
    value,
    onChange = () => null,
    noCheckmark = false,
    required = false,
    readOnly = false,
    prefix = undefined,
    suffix = undefined,
  } = props

  useEffect(() => {
    if (value) {
      setIcons(true)
    }
    else {
      setIcons(false)
    }
  }, [value])

  return (
    <TextField
      required={required}
      id={label}
      label={label}
      className={className}
      variant={variant}
      value={value}
      onChange={onChange ? (e) => onChange(e.target.value) : false}
      InputProps={{
        endAdornment: icons && !noCheckmark && (
          <InputAdornment position="end">
            <CheckIcon className={classes.icon} />
          </InputAdornment>
        ),
        inputComponent: inputComp,
        inputProps: { prefix, suffix },
        readOnly,
      }}
    />
  )
}
