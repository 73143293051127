import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import currencies from '../data/currencies'
import translationEN from './en/translation.json'
import translationHE from './he/translation.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    he: { translation: translationHE },
  },
  whitelist: currencies.map((item) => item.language.value),
  fallbackLng: currencies[0].language.value,
  interpolation: { escapeValue: false },
  detection: { checkWhitelist: true },
  debug: false,
})

export default i18n
