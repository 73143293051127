/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-input-mui/dist/style.css'
import PhoneInput from 'react-phone-input-mui'
import BigButton from './BigButton'
import BlurIconTextField from './BlurIconTextField'
import Errors from '../../../Errors'

const useStyles = makeStyles(({ palette }) => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '25.5rem',
  },
  names: {
    width: '50%',
    padding: 5,
  },
  rest: {
    width: '100%',
    margin: 5,
  },
  icon: {
    color: palette.primary.main,
  },
}), { name: 'PersonalInfo' })

export default function PersonalInfo(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const { setStep, data } = props
  const {
    first_name, set_first_name,
    last_name, set_last_name,
    phone, set_phone,
    email, set_email,
    id, set_id,
    date_of_birth, set_date_of_birth,
    errors, setErrors,
  } = data

  const clickContinue = () => {
    const errorsToSet = []
    const phoneValid = isValidPhoneNumber(phone || '')

    if (!first_name) errorsToSet.push('First name is required')
    if (!last_name) errorsToSet.push('Last name is required')
    if (!phoneValid) errorsToSet.push(`Phone number ${phone} is invalid`)
    if (!email) errorsToSet.push('Email is required')
    if (!id) errorsToSet.push('Personal ID is required')
    if (!date_of_birth) errorsToSet.push('Date of Birth is required')

    setErrors(errorsToSet)

    if (!errorsToSet.length) {
      setStep(4)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {t('Let\'s start by getting to know you')}
      </div>
      <div className={classes.optionsContainer}>
        <div className={classes.names}>
          <BlurIconTextField
            label={t('First Name')}
            value={first_name}
            onChange={(value) => set_first_name(value)}
            className=""
            required
          />
        </div>
        <div className={classes.names}>
          <BlurIconTextField
            label={t('Last Name')}
            value={last_name}
            onChange={(value) => set_last_name(value)}
            className=""
            required
          />
        </div>
        <PhoneInput
          placeholder={t('Mobile Phone')}
          defaultCountry="il"
          value={phone}
          onChange={set_phone}
          component={TextField}
          inputClass={classes.rest}
          inputExtraProps={{
            autoComplete: 'phone',
            variant: 'outlined',
            style: { width: '24.9rem' },
          }}
        />
        <BlurIconTextField
          label={t('Email Address')}
          value={email}
          onChange={(value) => set_email(value)}
          className={classes.rest}
          required
        />
        <BlurIconTextField
          label={t('Personal ID')}
          value={id}
          onChange={(value) => set_id(value)}
          className={classes.rest}
          required
        />
        <BlurIconTextField
          label={t('Date of Birth (DD/MM/YYYY)')}
          value={date_of_birth}
          onChange={(value) => set_date_of_birth(value)}
          className={classes.rest}
          required
        />
      </div>

      <Errors errors={errors} />

      <div className={classes.buttonContainer}>
        <BigButton onClick={clickContinue} label={t('Continue')} />
      </div>
    </div>
  )
}
