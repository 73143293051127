/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BigButton from './BigButton'
import HouseIcon from '../../../../assets/icons/HouseIcon'
import CreditCard from '../../../../assets/icons/CreditCard'
import Pill from '../../../../assets/icons/Pill'
import Diamond from '../../../../assets/icons/Diamond'
import Car from '../../../../assets/icons/Car'
import HouseHold from '../../../../assets/icons/HouseHold'
import Birthday from '../../../../assets/icons/Birthday'
import Business from '../../../../assets/icons/Business'
import Taxes from '../../../../assets/icons/Taxes'
import Errors from '../../../Errors'

const useStyles = makeStyles(() => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '25.5rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '8rem',
    height: '6rem',
    margin: '0.25rem',
    borderRadius: 8,
    transition: 'all 0.175s ease 0s',
    border: '0.0625rem solid rgb(230, 230, 230)',
    cursor: 'pointer',
    '&[aria-selected="true"]': {
      border: '0.0625rem solid rgb(0, 176, 175)',
      boxShadow: 'rgb(0 176 175 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 251, 251)',
    },
    '&:hover': {
      border: '0.0625rem solid rgb(0, 176, 175)',
      boxShadow: 'rgb(0 176 175 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 251, 251)',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '0.715rem',
    letterSpacing: '0.0625rem',
    transition: 'all 0.175s ease 0s',
    lineHeight: '0.8125rem',
    marginTop: '0.625rem',
    color: 'rgb(97, 97, 97)',
    '&[aria-selected="true"]': {
      color: 'rgb(0, 176, 175)',
    },
    '&:hover': {
      color: 'rgb(0, 176, 175)',
    },
  },
}), { name: 'LoanType' })

export default function LoanType(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const { setStep, data } = props
  const {
    loan_type,
    set_loan_type,
    errors,
    setErrors,
  } = data

  const clickContinue = () => {
    const errorsToSet = []

    if (!loan_type) errorsToSet.push('Selection is required')

    setErrors(errorsToSet)

    if (!errorsToSet.length) {
      setStep(2)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {t('How do you plan on using your loan?')}
      </div>
      <div className={classes.subHeader}>
        {t('Select the option that works best for you')}
      </div>
      <div className={classes.optionsContainer}>
        {[
          {
            name: t('CONSOLIDATE DEBT'),
            Icon: () => <CreditCard />,
          },
          {
            name: t('HOME IMPROVEMENT'),
            Icon: () => <HouseIcon />,
          },
          {
            name: t('MEDICAL OR DENTAL'),
            Icon: () => <Pill />,
          },
          {
            name: t('BIG PURCHASE'),
            Icon: () => <Diamond />,
          },
          {
            name: t('AUTO / MOTOR'),
            Icon: () => <Car />,
          },
          {
            name: t('HOUSEHOLD EXPENSES'),
            Icon: () => <HouseHold />,
          },
          {
            name: t('SPECIAL OCCASIONS'),
            Icon: () => <Birthday />,
          },
          {
            name: t('BUSINESS'),
            Icon: () => <Business />,
          },
          {
            name: t('TAXES'),
            Icon: () => <Taxes />,
          },
        ].map(({ name, Icon }) => (
          <div
            onClick={() => set_loan_type(name)}
            aria-selected={loan_type === name}
            key={name}
            className={classes.card}
          >
            <Icon />
            <div aria-selected={loan_type === name} className={classes.text}>
              {name}
            </div>
          </div>
        ))}
      </div>

      <Errors errors={errors} />

      <div className={classes.buttonContainer}>
        <BigButton onClick={clickContinue} label={t('Continue')} />
      </div>
    </div>
  )
}
