import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InvestTypes from './InvestTypes'
import Content from './Content'

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: 10,
    paddingTop: 20,
  },
  container: {
    width: '100%',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '100%',
    borderRadius: 16,
  },
}), { name: 'CardMain' })

export default function CardMain(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <InvestTypes />
        <Content />
      </div>
    </div>
  )
}
