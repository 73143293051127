import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Route, Switch } from 'react-router-dom'

import Loader from './Components/Loader'
import { setEnqueueSnackbar } from './lib/snackbar'
import MainRouter from './MainRouter'
import LendingCalculator from './Components/LendingCalculator'

const useStyles = makeStyles({
  app: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
}, { name: 'App' })

function App(props) {
  const classes = useStyles(props)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setEnqueueSnackbar(enqueueSnackbar)
  }, [enqueueSnackbar])

  return (
    <div className={classes.app}>
      <Switch>
        <Route path="/lending">
          <LendingCalculator />
        </Route>
        <Route path="/">
          <MainRouter />
        </Route>
      </Switch>
      <Loader />
    </div>
  )
}

export default connect()(App)
