import { useTheme } from '@material-ui/core'
import React from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'

const data = [
  {
    name: 'AA',
    current: 3,
    target: 10,
  },
  {
    name: 'A',
    current: 32,
    target: 21,
  },
  {
    name: 'B',
    current: 20,
    target: 25,
  },
  {
    name: 'C',
    current: 2,
    target: 27,
  },
  {
    name: 'D',
    current: 1,
    target: 11,
  },
  {
    name: 'E',
    current: 1,
    target: 5,
  },
  {
    name: 'CASH',
    current: 5,
    target: 48,
  },
]

export default function BarGraph() {
  const theme = useTheme()
  const { palette: { primary, secondary } } = theme

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <defs>
          <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={secondary.main} stopOpacity={1} />
            <stop offset="95%" stopColor={secondary.light} stopOpacity={1} />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="color5" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={primary.light} stopOpacity={1} />
            <stop offset="95%" stopColor={primary.main} stopOpacity={1} />
          </linearGradient>
        </defs>
        <Bar dataKey="target" fill="url(#color4)" opacity={0.8} />
        <Bar dataKey="current" fill="url(#color5)" opacity={0.8} />
      </BarChart>
    </ResponsiveContainer>
  )
}
