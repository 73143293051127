import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import SearchBar from '../Invest/SearchBar'
import TableSecond from './TableSecond'
import AutoInvestDialog from './AutoInvestDialog'
import data from './contentData'

const headers = [
  { header: '', minWidth: 50 },
  { header: 'Title', minWidth: 70 },
  { header: 'Total Amount', minWidth: 100 },
  { header: 'Invested', minWidth: 70 },
  { header: 'Reinvested', minWidth: 70 },
  { header: 'Interest Rate' },
  { header: 'Term', minWidth: 70 },
  { header: 'Per Single Loan' },
  { header: 'Reinvest' },
  { header: 'Status' },
]

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    marginTop: 10,
    alignSelf: 'center',
  },
  button: {
    fontWeight: 'bold',
  },
}), { name: 'Content' })

export default function Content(props) {
  const classes = useStyles(props)

  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(0)

  const onClick = () => setOpen(true)

  return (
    <div className={classes.container}>
      <SearchBar isPaper />
      <TableSecond headers={headers} items={data} />
      <div className={classes.addButton}>
        <Button
          color="secondary"
          variant="contained"
          fontWeight="bold"
          className={classes.button}
          onClick={onClick}
        >
          Add Auto-Invest Policy
        </Button>
      </div>
      <AutoInvestDialog
        open={open}
        step={step}
        setStep={setStep}
        handleClose={() => {
          setStep(0)
          setOpen(false)
        }}
      />
    </div>
  )
}
