import React from 'react'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'flex-end',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonText: {
    display: 'inline-flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    border: 0,
    minWidth: 300,
    justifyContent: 'center',
    borderRadius: 20,
    padding: '0 20px',
    fontSize: 17,
    lineHeight: 3.5,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    '&[aria-disabled="true"]': {
      background: theme.palette.grey[200],
      color: theme.palette.grey[400],
      cursor: 'default',
    },
    '&:active': {
      transform: 'scale(.98)',
    },
  },
}), { name: 'BigButton' })

const classesPrefix = 'MuiButtonBase-root'

function BigButton(props) {
  const classes = useStyles()
  const {
    label,
    onClick = () => { },
  } = props

  return (
    <div className={classes.container}>
      <button
        type="button"
        onClick={onClick}
        className={`${classesPrefix} ${classes.button}`}
      >
        <span
          className={classes.buttonText}
        >
          {label}
        </span>
      </button>
    </div>
  )
}

export default connect()(BigButton)
