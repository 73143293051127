import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const backcolors = {
  'Car Loan': 'rgba(255, 140, 0, 0.1)',
  Mortgage: 'rgba(7, 149, 244, 0.1)',
  Taxes: 'rgba(0, 230, 140, 0.1)',
}
const colors = {
  'Car Loan': 'rgba(255, 140, 0, 1)',
  Mortgage: 'rgba(7, 149, 244, 1)',
  Taxes: 'rgba(0, 230, 144, 1)',
}

const useStyles = makeStyles(() => ({
  loanType: {
    borderRadius: 4,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: ({ row }) => backcolors[row],
    color: ({ row }) => colors[row],
  },
}), { name: 'TagifyTag' })

export default function TagifyTag(props) {
  const classes = useStyles(props)

  const { row, putClass } = props

  return (
    <div className={classnames({ [classes.loanType]: putClass })}>
      {row}
    </div>
  )
}
