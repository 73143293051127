import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { Button, Container } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import ArrowUpper from '../../../assets/icons/ArrowUpper'
import Line from './Line'

const useStyles = makeStyles(({ palette }) => ({
  mainContainer: {
    width: '50%',
    padding: 10,
    paddingTop: 20,
  },
  container: {
    width: '100%',
    padding: 15,
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
  },
  header: {
    color: 'rgba(77, 77, 77, 0.6)',
    fontSize: 16,
    fontWeight: 'bold',
    paddingLeft: 12,
    marginTop: 10,
    marginBottom: 2,
  },
  subHeader: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  precentContainer: {
    color: palette.primary.main,
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginLeft: 12,
    flexDirection: 'row',
  },
  headerMain: {
    paddingLeft: 10,
    fontSize: 20,
    fontWeight: 'bold',
  },
  chartContainer: {
    height: '40vh',
    paddingTop: 20,
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
  },
  row: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    marginRight: 30,
    flexDirection: 'row',
  },
  buttonContainer2: {
    display: 'flex',
    marginBottom: 20,
    width: '77%',
    flexDirection: 'row',
  },
  button: {
    width: '50%',
    padding: 10,
  },
  button4: {
    width: '70%',
  },
  button2: {
    width: '100%',
    fontWeight: 'bold',
  },
  button3: {
    fontSize: 20,
  },
  whiteText: {
    color: 'white',
  },
  distributionHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.6,
    marginBottom: 20,
    letterSpacing: 1,
  },
}), { name: 'CardProtfolio' })

export default function CardProtfolio(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.headerMain}>
          Your Portfolio Balance
        </div>
        <Container className={classes.chartContainer}>
          <div className={classes.headContainer}>
            <div className={classes.row}>
              <div>
                <div className={classes.subHeaderContainer}>
                  <div className={classes.subHeader}>
                    $ 29,260
                  </div>
                  <div className={classes.precentContainer}>
                    <span>
                      15%
                    </span>
                    <ArrowUpper />
                  </div>
                </div>
                <div className={classes.header}>
                  Overall balance
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <div className={classes.button}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classnames(classes.button2, classes.whiteText)}
                  >
                    Deposit
                  </Button>
                </div>
                <div className={classes.button}>
                  <Button
                    variant="outlined"
                    className={classes.button2}
                  >
                    Withdraw
                  </Button>
                </div>
              </div>
              <div className={classes.buttonContainer2}>
                <div className={classes.button4}>
                  <Button
                    color="secondary"
                    className={classnames(classes.button2, classes.button3)}
                    startIcon={<AddIcon fontSize="large" />}
                  >
                    Add Wallet
                  </Button>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.distributionHeader}>
                Portfolio Distribution
              </div>
              <Line label="A | 6,601" color="primary" precent="73%" />
              <Line label="B | 4,126" color="primary" precent="40%" />
              <Line label="CASH | 1,031" color="primary" precent="15%" />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
