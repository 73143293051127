import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Container, useTheme,
} from '@material-ui/core'

import ArrowUp from '../../../assets/icons/ArrowUp'
import ArrowDown from '../../../assets/icons/ArrowDown'
import Protfolio from '../../MainScreen/Protfolio'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '32%',
    padding: 15,
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10,
    marginBottom: 2,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? palette.primary.main : palette.error.main),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginLeft: 12,
    flexDirection: 'row',
  },
  chartContainer: {
    height: '16vh',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    fontWeight: 'bold',
  },
}), { name: 'Card' })

export default function Card(props) {
  const classes = useStyles(props)
  const theme = useTheme()
  const history = useHistory()
  const { palette: { primary, error } } = theme

  const {
    header,
    subHeader,
    precent,
    increase,
    data,
    id,
    buttonText = '',
  } = props

  return (
    <Container className={classes.container}>
      <div className={classes.headContainer}>
        <div>
          <div className={classes.header}>
            {header}
          </div>
          <div className={classes.subHeaderContainer}>
            <div className={classes.subHeader}>
              {subHeader}
            </div>
            <div className={classes.precentContainer}>
              <span>
                {precent}
              </span>
              {increase ? (<ArrowUp />) : (<ArrowDown />)}
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => history.push(buttonText.toLowerCase())}
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <div className={classes.chartContainer}>
        <Protfolio id={id} data={data} color={increase ? primary : error} />
      </div>
    </Container>
  )
}
