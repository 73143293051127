/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="36" height="28" viewBox="0 0 36 28" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.41189 11.4795H1.00012V7.95947H5.41189V11.4795Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6.88259 27.3222H2.47083V23.8022H6.88259V27.3222Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M33.3533 27.3222H28.9415V23.8022H33.3533V27.3222Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M23.0589 23.7975H34.8237V16.7575L30.4119 11.4775H5.41189L1.00012 16.7575V23.7975H12.7648" fill="white"></path><path d="M23.0589 23.7975H34.8237V16.7575L30.4119 11.4775H5.41189L1.00012 16.7575V23.7975H12.7648" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M23.0589 25.5622H12.7648V20.2822H23.0589V25.5622Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M27.4707 0.921875H8.35304L5.41187 11.4819H30.4119L27.4707 0.921875Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M34.8235 11.4795H30.4117V7.95947H34.8235V11.4795Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M34.8239 20.2783H28.9415V16.7583H34.8239V20.2783Z" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.9117 16.7612V15.0012" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6.88247 20.2783H1.00012V16.7583H6.88247V20.2783Z" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
