const currencies = [
  {
    ticker: 'USD',
    symbol: '$',
    language: {
      label: 'English',
      value: 'en',
    },
  },
  {
    ticker: 'ILS',
    symbol: '₪',
    language: {
      label: 'עברית',
      value: 'he',
    },
  },
]

export default currencies
