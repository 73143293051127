/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled, Slider, Button } from '@mui/material'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import styles from '../styles/LendingCalculator.module.css'
import { useCurrency } from '../contexts/CurrencyContext'
import lendingRates from '../data/lending-rates'
import Header from './Header'
import Errors from './Errors'
import Formatter from './InputFormatters/Formatter'
import BlurIconTextField from './NewScreens/Borrow/DialogItems/BlurIconTextField'

const CustomSlider = styled(Slider)(() => ({
  color: 'var(--accent)',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: '#000',
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}))

function Rate({ children, value }) {
  return (
    <div className={styles.rate}>
      <h5>
        {children}
      </h5>
      <p>
        {value}
      </p>
    </div>
  )
}

function LendingCalculator() {
  const { t } = useTranslation()

  const minAmount = 1000
  const maxAmount = 50000
  const numOfDecibelsRate = 3
  const numOfDecibelsAmount = 0
  const history = useHistory()
  const { currency } = useCurrency()

  const [term, setTerm] = useState(24)

  const getApyWithTermChange = () => {
    const rateObj = lendingRates.find((rate) => rate.minMonths <= term && rate.maxMonths > term)

    if (!rateObj) return 0

    return rateObj.apy
  }

  const [apy, setApy] = useState(getApyWithTermChange())
  const [amount, setAmount] = useState(maxAmount)
  const [errors, setErrors] = useState([])

  const verifyAmount = (num = 0) => {
    let valid = true
    const youCannotTakeLoan = 'You cannot take a loan of'
    const invalidLoanError = `${youCannotTakeLoan} ${currency.symbol}${num}`

    for (let i = 0; i < errors.length; i++) {
      const err = errors[i]

      if (err.indexOf(youCannotTakeLoan) !== -1) {
        const copyOfErrors = [...errors]
        copyOfErrors.splice(i, 1)
        setErrors(copyOfErrors)
      }
    }

    if (num < minAmount || num > maxAmount) {
      setErrors((prev) => [...prev, invalidLoanError])

      valid = false
    }

    return valid
  }

  const getInterestRate = (period = 'year', asNumberType = false) => {
    let num = 0

    switch (period) {
      case 'day':
      case 'daily':
        num = (apy / 365)
        break

      case 'month':
      case 'monthly':
        num = (apy / 365) * (365 / 12)
        break

      case 'year':
      case 'yearly':
        num = apy
        break

      default:
        break
    }

    num = num.toFixed(numOfDecibelsRate)

    return asNumberType ? Number(num) : num
  }

  const getInterestAmount = (period = 'year', asNumberType = false) => {
    const numOfYears = term / 12
    const num = (amount * ((getInterestRate(period) * numOfYears) / 100)).toFixed(numOfDecibelsAmount)

    return asNumberType ? Number(num) : num
  }

  const handleAmountChange = (e, value) => {
    const x = typeof value === 'number' ? value : value[0]

    setAmount(x)
  }

  const handleTermChange = (e, value) => {
    const x = typeof value === 'number' ? value : value[0]

    setTerm(x)
    setApy(getApyWithTermChange())
  }

  const clickSubmit = () => {
    setErrors([])

    if (verifyAmount(amount)) {
      history.push(`/borrow?amount=${amount}&term=${term}`)
    }
  }

  useEffect(() => {
    verifyAmount(amount)
  }, [amount])

  return (
    <div className={styles.page}>
      <Header />

      <main className={styles.calculator}>
        <h1>{t('Lending Calculator')}</h1>

        <div className={styles.content}>
          <div className={styles.leftSide}>
            <div className={styles.sliderWrapper}>
              <div className={`${styles.titleAndInput} ${currency.language.value === 'he' ? 'rtl' : ''}`}>
                <h3>
                  {t('I\'m interested in a loan of:')}
                </h3>
                <BlurIconTextField
                  className={styles.amountInp}
                  value={amount}
                  onChange={(value) => handleAmountChange({}, Number(value))}
                  inputComp={Formatter}
                  prefix={currency.symbol}
                  noCheckmark
                />
              </div>
              <CustomSlider
                value={amount}
                onChange={handleAmountChange}
                valueLabelDisplay="auto"
                min={minAmount}
                max={maxAmount}
                step={1000}
              />
            </div>

            <div className={styles.sliderWrapper}>
              <div className={`${styles.titleAndInput} ${currency.language.value === 'he' ? 'rtl' : ''}`}>
                <h3>
                  {t('For a period of:')}
                </h3>
                <BlurIconTextField
                  className={styles.amountInp}
                  value={term / 12}
                  readOnly
                  inputComp={Formatter}
                  suffix={t('years')}
                  noCheckmark
                />
              </div>
              <CustomSlider
                value={term}
                onChange={handleTermChange}
                valueLabelDisplay="auto"
                marks={[
                  {
                    value: 12,
                    label: `1 ${t('year')}`,
                  },
                  {
                    value: 60,
                    label: `5 ${t('years')}`,
                  },
                ]}
                min={12}
                max={60}
                step={3}
              />
            </div>
          </div>

          <div className={styles.rightSide}>
            <div className={styles.ratesWrapper}>
              <Rate value={`${getInterestRate('day')}%`}>
                {t('Interest Rate')}
                <br />
                (&nbsp;%&nbsp;
                {t('per day')}
                &nbsp;)
              </Rate>
              <Rate value={`${currency.symbol}${getInterestAmount('day')}`}>
                {t('Interest Amount')}
                <br />
                (&nbsp;
                {currency.ticker}
                &nbsp;
                {t('per day')}
                &nbsp;)
              </Rate>
            </div>
            <div className={styles.ratesWrapper}>
              <Rate value={`${getInterestRate('month')}%`}>
                {t('Interest Rate')}
                <br />
                (&nbsp;%&nbsp;
                {t('per month')}
                &nbsp;)
              </Rate>
              <Rate value={`${currency.symbol}${getInterestAmount('month')}`}>
                {t('Interest Amount')}
                <br />
                (&nbsp;
                {currency.ticker}
                &nbsp;
                {t('per month')}
                &nbsp;)
              </Rate>
            </div>
            <div className={styles.ratesWrapper}>
              <Rate value={`${getInterestRate('year')}%`}>
                {t('Interest Rate')}
                <br />
                (&nbsp;%&nbsp;
                {t('per year')}
                &nbsp;)
              </Rate>
              <Rate value={`${currency.symbol}${getInterestAmount('year')}`}>
                {t('Interest Amount')}
                <br />
                (&nbsp;
                {currency.ticker}
                &nbsp;
                {t('per year')}
                &nbsp;)
              </Rate>
            </div>
          </div>
        </div>

        <Errors errors={errors} />

        <Button className={styles.btn} onClick={clickSubmit}>
          {t('Continue Request')}
          &nbsp;
          <PlayArrowRoundedIcon />
        </Button>
      </main>
    </div>
  )
}

export default LendingCalculator
