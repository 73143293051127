import React from 'react'
import NumberFormat from 'react-number-format'

function Formatter(props) {
  const {
    inputRef,
    onChange,
    name,
    prefix = '',
    suffix = '',
    ...other
  } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        const { value } = values

        onChange({ target: { name, value } })
      }}
      thousandSeparator
      isNumericString
      prefix={`${prefix} `}
      suffix={` ${suffix}`}
    />
  )
}

export default Formatter
