import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const backcolors = {
  Pending: 'rgba(255, 140, 0, 0.1)',
  Canceled: 'rgba(255, 20, 30, 0.1)',
  Approved: 'rgba(0, 230, 140, 0.1)',
}
const colors = {
  Pending: 'rgba(255, 140, 0, 1)',
  Canceled: 'rgba(255, 20, 30, 1)',
  Approved: 'rgba(0, 230, 144, 1)',
}

const useStyles = makeStyles(() => ({
  loanType: {
    borderRadius: 4,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: ({ row }) => backcolors[row],
    color: ({ row }) => colors[row],
  },
}), { name: 'TagifyTag' })

export default function TagifyTag(props) {
  const classes = useStyles(props)

  const { row } = props

  return (
    <div className={classnames({ [classes.loanType]: true })}>
      {row}
    </div>
  )
}
