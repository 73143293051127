import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    boxShadow: 'none',
    marginBottom: spacing(),
    background: ({ isPaper }) => (isPaper ? palette.background.paper : palette.background.default),
    width: '100%',
  },
  input: {
    fontWeight: 'bold',
    marginLeft: spacing(1),
    flex: 1,
  },
  iconButton: {
    fontWeight: 'bold',
  },
}), { name: 'SearchBar' })

export default function SearchBar(props) {
  const classes = useStyles(props)
  const {
    value,
    onChange = () => { },
    onFocus = () => { },
    onBlur = () => { },
  } = props

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        value={value}
        onChange={onChange}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <IconButton type="submit" disabled className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
