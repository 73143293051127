/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BigButton from './BigButton'
import JustMe from '../../../../assets/icons/JustMe'
import Us from '../../../../assets/icons/Us'
import Errors from '../../../Errors'

const useStyles = makeStyles(() => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    width: '25.5rem',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '6rem',
    margin: '0.25rem',
    borderRadius: 8,
    transition: 'all 0.175s ease 0s',
    border: '0.0625rem solid rgb(230, 230, 230)',
    cursor: 'pointer',
    '&[aria-selected="true"]': {
      border: '0.0625rem solid rgb(0, 176, 175)',
      boxShadow: 'rgb(0 176 175 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 251, 251)',
    },
    '&:hover': {
      border: '0.0625rem solid rgb(0, 176, 175)',
      boxShadow: 'rgb(0 176 175 / 15%) 0px 0px 0.25rem',
      backgroundColor: 'rgb(240, 251, 251)',
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '0.715rem',
    letterSpacing: '0.0625rem',
    transition: 'all 0.175s ease 0s',
    lineHeight: '0.8125rem',
    marginTop: '0.625rem',
    color: 'rgb(97, 97, 97)',
    '&[aria-selected="true"]': {
      color: 'rgb(0, 176, 175)',
    },
    '&:hover': {
      color: 'rgb(0, 176, 175)',
    },
  },
}), { name: 'WhosApplying' })

export default function WhosApplying(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const { setStep, data } = props
  const {
    num_of_loan_requesters,
    set_num_of_loan_requesters,
    errors,
    setErrors,
  } = data

  const clickContinue = () => {
    const errorsToSet = []

    if (!num_of_loan_requesters) errorsToSet.push('Selection is required')

    setErrors(errorsToSet)

    if (!errorsToSet.length) {
      setStep(3)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {t('Who\'s applying?')}
      </div>
      <div className={classes.subHeader}>
        {t('Adding another person may improve your loan options')}
      </div>
      <div className={classes.optionsContainer}>
        {[
          {
            name: t('JUST ME'),
            Icon: () => <JustMe />,
          },
          {
            name: t('TWO OF US'),
            Icon: () => <Us />,
          },
        ].map(({ name, Icon }) => (
          <div
            onClick={() => set_num_of_loan_requesters(name)}
            aria-selected={num_of_loan_requesters === name}
            key={name}
            className={classes.card}
          >
            <div style={{ width: 48, height: 48 }}>
              <Icon />
            </div>
            <div aria-selected={num_of_loan_requesters === name} className={classes.text}>
              {name}
            </div>
          </div>
        ))}
      </div>

      <Errors errors={errors} />

      <div className={classes.buttonContainer}>
        <BigButton onClick={clickContinue} label={t('Continue')} />
      </div>
    </div>
  )
}
