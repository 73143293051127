/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import BigButton from '../../Borrow/DialogItems/BigButton'
import BlurIconTextField from '../../Borrow/DialogItems/BlurIconTextField'
import Sliders from '../Sliders'
import IOSSwitch from '../IOSSwitch'

function NumberFormatCustom(props) {
  const {
    inputRef,
    onChange,
    ...other
  } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      prefix="₪ "
    />
  )
}

const useStyles = makeStyles(({ palette }) => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '28.5rem',
  },
  names: {
    width: '50%',
    padding: 7,
  },
  rest: {
    width: '100%',
    margin: 5,
  },
  icon: {
    color: palette.primary.main,
  },
  reinvest: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },
}), { name: 'CustomPolicy' })

export default function CustomPolicy(props) {
  const classes = useStyles(props)

  const { setStep } = props

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        Great! lets configurate some details
      </div>
      <div className={classes.subHeader}>
        Enter the options you&lsquo;ll like
      </div>
      <div className={classes.optionsContainer}>
        <BlurIconTextField label="Title" className={classes.rest} />
        <BlurIconTextField label="Total amount" inputComp={NumberFormatCustom} className={classes.rest} />
        <div className={classes.names}>
          <BlurIconTextField label="Min Amount" inputComp={NumberFormatCustom} className="" />
        </div>
        <div className={classes.names}>
          <BlurIconTextField label="Max Amount" inputComp={NumberFormatCustom} className="" />
        </div>
        <Sliders />
        <div className={classes.reinvest}>
          <div>Reinvesting</div>
          <IOSSwitch checked />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <BigButton onClick={() => setStep(2)} label="Continue" />
      </div>
    </div>
  )
}
