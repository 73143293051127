/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { AppBar, Button } from '@mui/material'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import SelectLanguageAndCurrency from './SelectLanguageAndCurrency'
import { DRAWER_WIDTH } from '../consts'

const useStyles = makeStyles(({ spacing, measurements, palette }) => ({
  appBarDefault: {
    height: measurements.headerHeight,
    flexDirection: 'row !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    background: `linear-gradient(to right, ${palette.primary.light}, rgb(0, 14, 113))`,
    border: '0 !important',
  },
  appBarDashboard: {
    height: measurements.headerHeight,
    width: `calc(100% - ${DRAWER_WIDTH}px) !important`,
    marginLeft: DRAWER_WIDTH,
    flexDirection: 'row !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    background: `linear-gradient(to right, ${palette.primary.light}, ${palette.secondary.main})`,
    border: '0 !important',
  },
  accountIcon: {
    padding: `${spacing(0.5)}px ${spacing(2)}px`,
    borderRadius: 8,
    color: 'white !important',
    borderColor: 'white !important',
    textTransform: 'none',
  },
  logoContainer: {
    color: 'white !important',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    cursor: 'pointer',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 20,
    },
  },
  drawerPaper: {
    width: 386,
    boxShadow: '2px 3px 8px #888888',
  },
}), { name: 'Header' })

function Header(props) {
  const classes = useStyles(props)
  const history = useHistory()
  const location = useLocation()

  if (location.pathname === '/login' || location.pathname === '/sidebar') return null

  const {
    // name = 'John Doe',
    name = undefined,
    isDashboard,
  } = props

  return (
    <AppBar
      color="primary"
      position={isDashboard ? 'fixed' : 'static'}
      classes={{ root: isDashboard ? classes.appBarDashboard : classes.appBarDefault }}
    >
      <div
        className={classes.logoContainer}
        onClick={() => history.push('/')}
      >
        <div>Open Finance</div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {name && (
          <Button
            variant="outlined"
            classes={{ root: classes.accountIcon }}
          >
            {name}
          </Button>
        )}

        <SelectLanguageAndCurrency />
      </div>
    </AppBar>
  )
}
const mapStateToProps = () => ({})
export default connect(mapStateToProps)(Header)
