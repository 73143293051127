const {
  REACT_APP_CENTRALIZED_AUTH_APP_ID: CENTRALIZED_AUTH_APP_ID,
  REACT_APP_CENTRALIZED_AUTH_HOST: CENTRALIZED_AUTH_HOST,
  NODE_ENV,
  REACT_APP_SUBSCRIPTION_URL: SUBSCRIPTION_URL,
  REACT_APP_LEAD_URL: LEAD_URL,
} = process.env

const LANGS = {
  he: 'he',
  en: 'en',
}

const COLORS = [
  '#7cb9e8',
  '#c9ffe5',
  '#eedfcd',
  '#f19bbb',
  '#f9d15b',
  '#cd9575',
  '#7fffd5',
  '#d0ff16',
  '#ff9866',
  '#f5c2c2',
  '#9f8170',
  '#c76875',
  '#a2a2d1',
  '#f0dc82',
  '#a3c1ad',
  '#64a0a0',
  '#42775d',
  '#e66771',
  '#c5cbe1',
  '#917896',
]

const CONSENT_COLORS = {
  valid: '#4d9999',
  received: '#f4a261',
  rejected: '#994d4d',
  updated: '#a1a1a1',
}

const CURRENT_MODEL_TABS = {
  sentences: 'sentences',
  entities: 'entities',
}

const PATHS = {
  models: '/models',
  deployments: '/deployments',
}

const MAIN_SCREEN_TABS = {
  models: 'models',
  deployments: 'deployments',
}

const CURRENT_DEPLOYMENT_TABS = {
  settings: 'settings',
  teach: 'teach',
  test: 'test',
  logs: 'logs',
}
const DRAWER_WIDTH = 240

export {
  CENTRALIZED_AUTH_APP_ID,
  CENTRALIZED_AUTH_HOST,
  DRAWER_WIDTH,
  LANGS,
  COLORS,
  CONSENT_COLORS,
  CURRENT_MODEL_TABS,
  PATHS,
  MAIN_SCREEN_TABS,
  CURRENT_DEPLOYMENT_TABS,
  NODE_ENV,
  LEAD_URL,
  SUBSCRIPTION_URL,
}
