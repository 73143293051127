import React from 'react'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  loanType: {
    borderRadius: 4,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(0, 230, 140, 0.1)',
    color: 'rgba(0, 230, 144, 1)',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}), { name: 'TagifyTag' })

export default function TagifyTag(props) {
  const classes = useStyles(props)

  const { row, index } = props

  return (
    <div className={classnames(classes.root, { [classes.loanType]: index === 1 })}>
      { row}
    </div>
  )
}
