/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="33" height="30" viewBox="0 0 33 30" fill="none"><path d="M14.0695 10.4553V2.1131L28.8075 12.1238V27.9741H3.50269V15.4607L14.0695 10.4553Z" fill="white"></path><path d="M1.00012 28.7247H31.5876" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.00012 16.6393L14.016 10.952" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.60522 15.929V28.7251" stroke="#00B0AF" stroke-linejoin="round"></path><path d="M12.0662 1L31.59 14.5071" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path d="M28.9858 12.3748V28.7256" stroke="#00B0AF" stroke-linejoin="round"></path><path d="M25.0773 10.3394V4.93671H27.6804V12.0458" stroke="#00B0AF" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M25.7307 23.0374H16.6195V28.7247H25.7307V23.0374Z" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.4791 23.0374V28.7247" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M20.5243 13.0848C20.5243 14.2634 19.6496 15.2175 18.5719 15.2175C17.4942 15.2175 16.6195 14.2634 16.6195 13.0848C16.6195 11.9075 17.4942 10.952 18.5719 10.952C19.6496 10.952 20.5243 11.9075 20.5243 13.0848Z" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M11.4166 21.6182H6.21021V28.7272H11.4166V21.6182Z" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.21021 18.7745H11.4166" stroke="#00B0AF" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.0154 2.42096V28.7244" stroke="#00B0AF" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
