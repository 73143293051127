import React from 'react'

function Errors({ errors = [] }) {
  return (
    <ul
      style={{
        margin: '0.5rem auto',
        width: 'fit-content',
        fontSize: '0.8rem',
        color: 'red',
        textAlign: 'left',
      }}
    >
      {errors.map((err) => (
        <li key={err}>{err}</li>
      ))}
    </ul>
  )
}

export default Errors
