import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem } from '@material-ui/core'
import { CONSENT_COLORS } from '../../../consts'
import AccordionHeader from './AccordionHeader'
import AccordionContent from './AccordionContent'

const useStyles = makeStyles(({ spacing, palette }) => ({
  listItem: {
    marginTop: spacing(),
    background: palette.background.paper,
    color: ({ consent: { status } }) => CONSENT_COLORS[status],
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
    borderLeft: ({ consent: { status } }) => `6px solid ${CONSENT_COLORS[status]}`,
  },
}), { name: 'ConsentItem' })

function ConsentItem(props) {
  const classes = useStyles(props)
  const [open, setOpen] = useState(false)
  const {
    consent,
  } = props

  const {
    key,
  } = consent

  return (
    <ListItem
      key={key}
      className={classes.listItem}
    >
      <AccordionHeader
        consent={consent}
        open={open}
        setOpen={setOpen}
      />
      <AccordionContent
        open={open}
        consent={consent}
      />
    </ListItem>
  )
}

export default ConsentItem
