import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import interestCalc from '../../lib/interestCalc'

const formatDate = () => {
  const today = new Date()
  const d = new Date(today.getTime() + 31 * 24 * 60 * 60 * 1000)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        EG
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}), { name: 'Lenders' })

export default function SignUp() {
  const classes = useStyles()
  const history = useHistory()
  const [date, setDate] = useState(formatDate())
  const [amount, setAmount] = useState(10000)

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LocalAtmIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Invest now!
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={amount}
                onChange={({ target: { value } }) => setAmount(value)}
                name="investAmount"
                variant="outlined"
                required
                fullWidth
                id="investAmount"
                label="Invest Amount"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="coin"
                variant="outlined"
                required
                fullWidth
                id="coin"
                label="Coin Type"
              />
            </Grid>
            <Grid item xs={12}>
              Return Date
              <TextField
                value={date}
                onChange={({ target: { value } }) => setDate(value)}
                variant="outlined"
                required
                fullWidth
                type="date"
                id="date"
                name="date"
              />
            </Grid>
            <Grid item xs={12}>
              {`Approximate earned interest rate: ${interestCalc(amount, date) + 0.5}%`}
            </Grid>
          </Grid>
          <Button
            fullWidth
            onClick={() => history.push('protfolio')}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Invest
          </Button>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}
