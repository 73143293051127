import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import BallotIcon from '@material-ui/icons/Ballot'
import {
  Checkbox,
  List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core'

const types = ['All', 'Car Loan', 'Household', 'Mortgage', 'Taxes']
const currency = ['All', 'EUR', 'NIS', 'DLR', 'CZK']

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: 256,
    height: '100%',
    borderRight: 'solid 1px rgba(0, 0, 0, 0.06)',
  },
  listItem: {
    width: 256 - 20,
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    letterSpacing: 1.5,
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: palette.background.paper,
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
    '&[aria-selected="true"]': {
      color: palette.primary.main,
      backgroundColor: palette.background.paper,
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.6)',
    '&[aria-selected="true"]': {
      color: palette.primary.main,
    },
  },
}), { name: 'InvestTypes' })

export default function InvestTypes(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <List>
        <ListItem
          className={classes.listItem}
        >
          <ListItemText primary="Type" />
        </ListItem>
        {types.map((text, i) => (
          <ListItem
            aria-selected={!i}
            className={classes.listItem}
            key={text}
          >
            <ListItemIcon className={classes.icon}>
              <Checkbox
                color="primary"
                checked
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <ListItem
          className={classes.listItem}
        >
          <ListItemText primary="Currency" />
        </ListItem>
        {currency.map((text, i) => (
          <ListItem
            aria-selected={i === 2}
            className={classes.listItem}
            key={text}
          >
            <ListItemIcon className={classes.icon}>
              <Checkbox
                color="primary"
                indeterminate={!i}
                checked={i === 2}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  )
}
