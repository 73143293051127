export default (amount, date) => {
  try {
    const value = parseInt(amount, 10)
    const daysToReturn = ((new Date(date)) - (new Date())) / 1000 / 60 / 60 / 24
    const v = (value * 2) / 10000
    const d = daysToReturn / 3
    return Math.floor(Math.log(v + d))
  }
  catch (e) {
    return 3
  }
}
