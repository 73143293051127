import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import BarGraph from '../../MainScreen/BarGraph'

const useStyles = makeStyles(({ palette }) => ({
  mainContainer: {
    width: '50%',
    padding: 10,
    paddingTop: 20,
  },
  container: {
    width: '100%',
    padding: 15,
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
  },
  header: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10,
    marginBottom: 2,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  subHeaderContainer: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  precentContainer: {
    color: ({ increase }) => (increase ? palette.primary.main : palette.error.main),
    display: 'flex',
    fonSize: 18,
    alignItems: 'center',
    marginLeft: 12,
    flexDirection: 'row',
  },
  chartContainer: {
    height: '40vh',
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}), { name: 'CardMain' })

export default function CardMain(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className={classes.headContainer}>
          <div>
            <div className={classes.subHeaderContainer}>
              <div className={classes.subHeader}>
                Portfolio
              </div>
            </div>
          </div>
        </div>
        <div className={classes.chartContainer}>
          <BarGraph />
        </div>
      </div>
    </div>
  )
}
