/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import BigButton from './BigButton'
import BlurIconTextField from './BlurIconTextField'
import Formatter from '../../../InputFormatters/Formatter'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import Errors from '../../../Errors'

const useStyles = makeStyles(({ palette }) => ({
  cardHeader: {
    fontSize: '1.5rem',
    fontWeight: 300,
    margin: '0px auto 1rem',
    color: 'rgb(77, 77, 77)',
  },
  subHeader: {
    fontSize: 17,
    margin: 8,
    color: 'rgb(77, 77, 77)',
  },
  buttonContainer: {
    marginTop: 16,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '25.5rem',
  },
  names: {
    width: '50%',
    padding: 5,
  },
  rest: {
    width: '100%',
    margin: 5,
  },
  icon: {
    color: palette.primary.main,
  },
}), { name: 'HowMuch' })

export default function HowMuch(props) {
  const { t } = useTranslation()
  const { currency } = useCurrency()
  const classes = useStyles(props)

  const { setStep, data } = props

  const {
    amount,
    set_amount,
    term,
    set_term,
    description,
    set_description,
    errors,
    setErrors,
  } = data

  const clickContinue = () => {
    const errorsToSet = []

    if (!amount) errorsToSet.push('Amount is required')
    if (!term) errorsToSet.push('Term is required')

    setErrors(errorsToSet)

    if (!errorsToSet.length) {
      setStep(1)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {t('Personal Loans')}
      </div>
      <div className={classes.subHeader}>
        {t('Low interest personal loans, for every need.')}
      </div>
      <div className={classes.optionsContainer}>
        <BlurIconTextField
          value={amount}
          onChange={(value) => set_amount(value)}
          inputComp={Formatter}
          prefix={currency.symbol}
          label={t('How much would you like to borrow?')}
          className={classes.rest}
          required
        />
        <BlurIconTextField
          value={term}
          onChange={(value) => set_term(value)}
          inputComp={Formatter}
          suffix={t('months')}
          label={t('For how long would you like to borrow?')}
          className={classes.rest}
          required
        />
        <BlurIconTextField
          value={description}
          onChange={(value) => set_description(value)}
          label={t('Describe your loan (optional)')}
          className={classes.rest}
        />
      </div>

      <Errors errors={errors} />

      <div className={classes.buttonContainer}>
        <BigButton onClick={clickContinue} label={t('Continue')} />
      </div>
    </div>
  )
}
