/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CommonLoading } from 'react-loadingg'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}), { name: 'Loading' })

export default function Loading(props) {
  const classes = useStyles(props)
  const { handleClose } = props

  useEffect(() => {
    setTimeout(() => {
      handleClose()
    }, 2500)
  }, [])

  return (
    <div className={classes.container}>
      <CommonLoading />
    </div>
  )
}
