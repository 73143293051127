import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 30,
  },
  header: {
    marginBottom: 7,
  },
  linearContainer: {
    width: ({ precent }) => precent,
    alignSelf: 'center',
  },
  linear: {
    borderRadius: 7,
    height: 5,
    marginRight: 7,
  },
  progressContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  text: {
    color: 'rgba(77, 77, 77, 0.7)',
  },
}), { name: 'Line' })

export default function Line(props) {
  const classes = useStyles(props)

  const { label, color, precent } = props

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {label}
      </div>
      <div className={classes.progressContainer}>
        <div className={classes.linearContainer}>
          <LinearProgress color={color} className={classes.linear} variant="determinate" value={100} />
        </div>
        <span className={classes.text}>{precent}</span>
      </div>
    </div>
  )
}
