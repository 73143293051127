import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { List, Typography } from '@material-ui/core'

import ConsentItem from './ConsentItem/ConsentItem'

const dataLoans = [
  [
    1,
    {
      status: 'valid',
      amount: '10,000',
      dueDate: '22.08.2022',
      rate: 3,
      description: 'For Dafna university',
      returned: '0',
      remaining: '10,300',
    },
  ],
  [
    2,
    {
      status: 'received',
      amount: '100,000',
      dueDate: '10.04.2022',
      rate: 6,
      description: 'New car',
      returned: '42,000',
      remaining: '64,000',
    },
  ],
  [
    3,
    {
      status: 'rejected',
      amount: '15,000',
      dueDate: '7 Days',
      rate: 3,
      description: 'PS5',
      returned: '13,000',
      remaining: '2,450',
    },
  ],
]

const dataInvest = [
  [
    1,
    {
      status: 'valid',
      amount: '10,000',
      dueDate: '22.08.2022',
      rate: 3,
      returned: '0',
      remaining: '10,300',
    },
  ],
  [
    3,
    {
      status: 'valid',
      amount: '15,000',
      dueDate: '01.04.2022',
      rate: 3,
      returned: '13,000',
      remaining: '2,450',
    },
  ],
]

const useStyles = makeStyles(({ spacing }) => ({
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  listParent: {
    width: '80%',
  },
  consentsHeader: {
    fontWeight: 'bold',
    padding: spacing(),
  },
  historyContainer: {
    marginTop: spacing(3),
  },
}), { name: 'ConsentList' })

function ConsentList(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.listContainer}>
      <List aria-label="actions list" className={classes.listParent}>
        <div>
          <Typography variant="h5" className={classes.consentsHeader}>
            Loans
          </Typography>
          {dataLoans.map(([key, consent]) => (
            <ConsentItem key={key} consent={consent} />
          ))}
        </div>
        <div>
          <Typography variant="h5" className={classes.consentsHeader}>
            Investments
          </Typography>
          {dataInvest.map(([key, consent]) => (
            <ConsentItem key={key} consent={consent} />
          ))}
        </div>
      </List>
    </div>
  )
}

export default ConsentList
