/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none"><path d="M1 28.75H35.5" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M30.9995 13.7501H5.49951V28.7501H30.9995V13.7501Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M31 10.4373V3.24927H26.5V7.11627" fill="white"></path><path d="M31 10.4373V3.24927H26.5V7.11627" stroke="#1AA8A7" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M18.25 1L1 13.75H35.5L18.25 1Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M14.4995 18.2501H9.99951V25.7501H14.4995V18.2501Z" stroke="#1AA8A7" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M26.4995 22.7501H18.9995V18.2501H26.4995V22.7501Z" stroke="#1AA8A7" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M15.9995 28.7501H8.49951V25.7501H15.9995V28.7501Z" stroke="#1AA8A7" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
