/* eslint-disable max-len */
/* eslint-disable object-property-newline */
/* eslint-disable camelcase */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  Container,
  IconButton,
  Slide,
  Toolbar,
} from '@mui/material'
import {
  Close as CloseIcon,
  ArrowBack,
  ArrowForward,
} from '@material-ui/icons'
import { useCurrency } from '../../../contexts/CurrencyContext'
import LoanType from './DialogItems/LoanType'
import WhosApplying from './DialogItems/WhosApplying'
import PersonalInfo from './DialogItems/PersonalInfo'
import Address from './DialogItems/Address'
import Income from './DialogItems/Income'
import Financial from './DialogItems/Financial'
import Loading from './DialogItems/Loading'
import HowMuch from './DialogItems/HowMuch'

const useStyles = makeStyles(({ spacing }) => ({
  dialog: {
    width: 650,
    minHeight: 615,
  },
  appBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2),
    zIndex: 3,
    border: 0,
  },
  logoContainer: {
    display: 'flex',
    fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 20,
    },
  },
}), { name: 'BorrowDialog' })

const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />))

export default function BorrowDialog(props) {
  const classes = useStyles()
  const { currency: { language } } = useCurrency()

  const {
    handleClose,
    open,
    setStep,
    step,
    addNewLoan,
    query,
  } = props

  const [amount, set_amount] = useState(query && query.amount ? query.amount : '')
  const [term, set_term] = useState(query && query.term ? query.term : '')
  const [phone, set_phone] = useState('')
  const [description, set_description] = useState('')
  const [loan_type, set_loan_type] = useState('')
  const [num_of_loan_requesters, set_num_of_loan_requesters] = useState('')
  const [first_name, set_first_name] = useState('')
  const [last_name, set_last_name] = useState('')
  const [email, set_email] = useState('')
  const [id, set_id] = useState('')
  const [date_of_birth, set_date_of_birth] = useState('')
  const [street, set_street] = useState('')
  const [city, set_city] = useState('')
  const [state, set_state] = useState('')
  const [zip_code, set_zip_code] = useState('')
  const [income_type, set_income_type] = useState('')
  const [annual_income, set_annual_income] = useState('')
  const [additional_income, set_additional_income] = useState('')
  const [monthly_rent_or_mortgage, set_monthly_rent_or_mortgage] = useState('')
  const [errors, setErrors] = useState([])

  const HowMuchData = {
    amount, set_amount,
    term, set_term,
    description, set_description,
    errors, setErrors,
  }

  const LoanTypeData = {
    loan_type, set_loan_type,
    errors, setErrors,
  }

  const WhosApplyingData = {
    num_of_loan_requesters, set_num_of_loan_requesters,
    errors, setErrors,
  }

  const PersonalInfoData = {
    first_name, set_first_name,
    last_name, set_last_name,
    email, set_email,
    phone, set_phone,
    id, set_id,
    date_of_birth, set_date_of_birth,
    errors, setErrors,
  }

  const AddressData = {
    street, set_street,
    city, set_city,
    state, set_state,
    zip_code, set_zip_code,
    errors, setErrors,
  }

  const IncomeData = {
    income_type, set_income_type,
    errors, setErrors,
  }

  const FinancialData = {
    annual_income, set_annual_income,
    additional_income, set_additional_income,
    monthly_rent_or_mortgage, set_monthly_rent_or_mortgage,
    errors, setErrors,
  }

  const allData = {
    amount,
    term,
    description,
    loan_type,
    num_of_loan_requesters,
    first_name,
    last_name,
    phone,
    email,
    id,
    date_of_birth,
    street,
    city,
    state,
    zip_code,
    income_type,
    annual_income,
    additional_income,
    monthly_rent_or_mortgage,
  }

  const clickBack = () => {
    if (step === 0) return handleClose()
    setStep(step - 1)
    setErrors([])
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div className={`${classes.dialog} ${language.value === 'he' ? 'rtl' : ''}`}>
        <div>
          <Toolbar className={classes.appBar}>
            <IconButton edge="start" color="inherit" onClick={clickBack} aria-label="close">
              {language.value === 'he' ? (
                <ArrowForward />
              ) : (
                <ArrowBack />
              )}
            </IconButton>
            <div className={classes.logoContainer}>
              <div />
            </div>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </div>
        <Container>
          {(step === 0) && (<HowMuch data={HowMuchData} setStep={setStep} />)}
          {(step === 1) && (<LoanType data={LoanTypeData} setStep={setStep} />)}
          {(step === 2) && (<WhosApplying data={WhosApplyingData} setStep={setStep} />)}
          {(step === 3) && (<PersonalInfo data={PersonalInfoData} setStep={setStep} />)}
          {(step === 4) && (<Address data={AddressData} setStep={setStep} />)}
          {(step === 5) && (<Income data={IncomeData} setStep={setStep} />)}
          {(step === 6) && (<Financial data={FinancialData} setStep={setStep} />)}
          {(step === 7) && (<Loading data={allData} addNewLoan={addNewLoan} setStep={setStep} handleClose={handleClose} />)}
        </Container>
      </div>
    </Dialog>
  )
}
