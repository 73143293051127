/* eslint-disable max-len */
import React from 'react'

function HouseIcon() {
  return (
    <svg width="24" height="32" viewBox="0 0 24 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.1739 31H1V1H15.3478L23.1739 8.82609V31Z" fill="white" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.3479 1V8.82609H23.174" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.32056 14.2874C5.32056 15.5043 6.30664 16.4878 7.52099 16.4878C8.73664 16.4878 9.72273 15.5043 9.72273 14.2874C9.72273 13.0743 8.73664 12.087 7.52099 12.087C6.30664 12.087 5.32056 11.1022 5.32056 9.88652C5.32056 8.67087 6.30664 7.68478 7.52099 7.68478C8.73664 7.68478 9.72273 8.67087 9.72273 9.88652" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.52173 16.4885V17.9572" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.52173 6.21804V7.68413" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.02124 21.8696H19.2608" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12.7595 19.2609H19.2604" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.3479 16.6522H19.2609" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.3479 14.0435H19.2609" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.02124 24.4783H19.2608" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.02124 27.087H19.2608" stroke="#1AA8A7" stroke-linecap="round" stroke-linejoin="round"></path></svg>
  )
}

export default HouseIcon
