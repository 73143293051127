/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CommonLoading } from 'react-loadingg'
import { LEAD_URL } from '../../../../consts'

const titleCase = (str) => str.split(' ').map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()).join(' ')
const numberWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}), { name: 'Loading' })

export default function Loading(props) {
  const classes = useStyles(props)
  const { handleClose, addNewLoan, data } = props
  const {
    amount,
    description,
    loan_type,
  } = data

  useEffect(() => {
    setTimeout(() => {
      try {
        fetch(LEAD_URL, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then((rawResponse) => rawResponse.json())
          .then((content) => {
            console.log(content)

            addNewLoan({
              amount: numberWithCommas(amount),
              plan: titleCase(loan_type),
              description: titleCase(description),
            })
            handleClose()
          })
      }
      catch (e) {
        console.log('some error has occured')
        console.log(e)
        handleClose()
      }
    }, 1200)
  }, [])

  return (
    <div className={classes.container}>
      <CommonLoading />
    </div>
  )
}
