import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Divider,
  List, ListItem, ListItemText,
} from '@material-ui/core'

const WIDTH = 200

const useStyles = makeStyles(() => ({
  panel: {
    height: 0,
    width: '100%',
    alignSelf: 'start',
    overflow: 'hidden',
    '&[aria-selected="true"]': {
      height: 140,
    },
    transition: 'height 0.2s ease-out',
  },
}), { name: 'AccordionContent' })

function AccordionContent(props) {
  const classes = useStyles(props)
  const {
    open,
    consent,
  } = props

  const {
    rate,
    returned,
    remaining,
  } = consent

  return (
    <div className={classes.panel} aria-selected={open}>
      <Divider />
      <List>
        <ListItem>
          <div style={{ width: WIDTH }}>
            <ListItemText
              primary="Returned :"
            />
          </div>
          <ListItemText
            primary={returned}
          />
        </ListItem>
        <ListItem>
          <div style={{ width: WIDTH }}>
            <ListItemText
              primary="Remaining:"
            />
          </div>
          <ListItemText
            primary={remaining}
          />
        </ListItem>
        <ListItem>
          <div style={{ width: WIDTH }}>
            <ListItemText
              primary="Rate:"
            />
          </div>
          <ListItemText
            primary={`${rate}%`}
          />
        </ListItem>
      </List>
    </div>
  )
}

export default AccordionContent
