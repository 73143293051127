import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Redirect, Route, Switch } from 'react-router-dom'
import Borrowers from './Components/MainScreen/Borrowers'
import Protfolio from './Components/MainScreen/Protfolio'
import Lenders from './Components/MainScreen/Lenders'
import AutoInvest from './Components/NewScreens/AutoInvest/AutoInvest'
import DashBoard from './Components/MainScreen/DashBoard'
import NavBar from './NavBar'
// import Invest from './Components/NewScreens/Invest/Invest'
import { DRAWER_WIDTH } from './consts'
import Borrow from './Components/NewScreens/Borrow/Borrow'
import Overview from './Components/NewScreens/Overview/Overview'
import SecondInvest from './Components/NewScreens/SecondInvest/SecondInvest'
import Header from './Components/Header'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.secondary.main})`,
    marginLeft: DRAWER_WIDTH,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    borderRight: '0px',
  },
  logoContainer: {
    cursor: 'pointer',
    display: 'flex',
    color: 'white',
    fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    userSelect: 'none',
    '& > div': {
      letterSpacing: '1.5px',
      fontSize: 20,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}), { name: 'SideDrawer' })

export default function SideDrawer() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header isDashboard />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <NavBar />
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/invest">
            <SecondInvest />
          </Route>
          <Route exact path="/borrow">
            <Borrow />
          </Route>
          <Route exact path="/autoinvest">
            <AutoInvest />
          </Route>
          <Route exact path="/main-screen">
            <Overview />
          </Route>
          <Route exact path="/borrowers">
            <Borrowers />
          </Route>
          <Route exact path="/dashboard">
            <DashBoard />
          </Route>
          <Route exact path="/lenders">
            <Lenders />
          </Route>
          <Route exact path="/protfolio">
            <Protfolio />
          </Route>

          <Route>
            <Redirect to="/lending" />
          </Route>
        </Switch>
      </main>
    </div>
  )
}
