import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import SearchBar from '../Invest/SearchBar'
import BorrowDialog from './BorrowDialog'
import BorrowTable from './BorrowTable'
import { addLoan } from '../../../store/actions/loans'

const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  listParent: {
    width: '80%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  consentsHeader: {
    fontWeight: 'bold',
  },
  button: {
    overflow: 'hidden',
    color: 'white',
    minWidth: 107,
    position: 'relative',
  },
  label: {
    transition: 'opacity 250ms  cubic-bezier(0.4, 0, 0.6, 1)',
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
  addButton: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    borderRadius: 16,
    boxShadow: '1px 1px 5px 1px rgba(0,0,0,0.3)',
    padding: 15,
    margin: 13,
  },
}), { name: 'Borrow' })

const getQuery = (queryStr) => {
  if (queryStr) {
    const queryArr = queryStr.substring(1, queryStr.length).split('&')
    const queryObj = {}

    queryArr.forEach((str) => {
      const [key, val] = str.split('=')

      queryObj[key] = val
    })

    return queryObj
  }

  return null
}

function Borrow(props) {
  const query = getQuery(useLocation().search)

  const classes = useStyles(props)
  const [open, setOpen] = useState(query && query.amount && query.term)
  const [step, setStep] = useState(0)

  const { dispatch, loans } = props

  const onClick = () => setOpen(true)

  const addNewLoan = ({
    amount,
    plan,
    description,
  }) => {
    dispatch(addLoan({
      status: 'Pending',
      amount,
      plan,
      dueDate: '10.08.2022',
      rate: 6,
      description,
      returned: '0',
      remaining: amount,
    }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.listContainer}>
        <SearchBar isPaper />
        <BorrowTable
          headers={['', ...Object.keys(Object.values(loans)[0]).map((e) => ({ header: e }))]}
          items={Object.values(loans)}
        />
        <div className={classes.addButton}>
          <Button
            color="secondary"
            variant="contained"
            fontWeight="bold"
            className={classes.button}
            onClick={onClick}
          >
            Create New Loan
          </Button>
        </div>
        <BorrowDialog
          open={open}
          addNewLoan={addNewLoan}
          step={step}
          setStep={setStep}
          handleClose={() => {
            setStep(0)
            setOpen(false)
          }}
          query={query}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ loans }) => ({ loans })

export default connect(mapStateToProps)(Borrow)
